import React from 'react'
import * as S from './styled'

function Intro () {
  return (
    <S.IntroContainer>
      <S.Text>
        <em>O <strong><em>Vento Sul 3</em></strong>, vai levar você para passear na esplendorosa <strong>Baía de Guanabara</strong> por aproximadamente <strong>3 horas</strong>. Você não esquecerá jamais!</em>
      </S.Text>
    </S.IntroContainer>
  )
}

export default Intro

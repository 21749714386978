import styled from 'styled-components'
import media from 'styled-media-query'

export const PricesContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
	max-width: var(--maxWidth);
	margin: 0 auto;

	${media.lessThan('huge')`
    padding: 0 var(--mobileGlobalPadding);
  `}

	${media.lessThan('large')`
    display: flex;
    flex-direction: column;
  `}
`
export const GridArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #eee;
	padding: 30px 12px;
	height: 100%;
	text-align: center;

	&:first-of-type {
		margin-bottom: 20px;
	}
`

export const Title = styled.h3`
	font-size: 1.6em;
	font-weight: 700;
`

export const Time = styled.p`
	font-size: 1em;
	margin-top: 10px;
`

export const IndividualPrice = styled.p`
	font-size: 2.5em;
	font-weight: 700;
	margin-top: 50px;
`

export const Appointment = styled.a`
	margin-top: 50px;
	background-color: var(--primaryColor);
	color: #fff;
	padding: 20px 40px;
	font-size: 1.6em;
	text-decoration: none;
`

export const GroupPrices = styled.div`
	min-width: 300px;
	margin-top: 20px;
`

export const Price = styled.p`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;

	strong {
		font-weight: 700;
		font-size: 1.6em;
	}
`

export const Infos = styled.div`
	width: 80%;
	margin-top: 40px;
	text-align: left;
	padding: 0 50px;
`

export const Contact = styled.div`
	margin-top: 20px;

	${media.lessThan('huge')`
		margin-bottom: 20px;
	`}

	p {
		font-weight: 700;
		margin-bottom: 40px;
	}

	a {
		margin-top: 50px;
		background-color: var(--primaryColor);
		color: #fff;
		padding: 20px 40px;
		font-size: 1.6em;
		text-decoration: none;

		span {
			margin-left: 10px;
		}
	}
`

export const Notes = styled.section`
	max-width: var(--maxWidth);
	margin: 100px auto var(--globalTopBottomMargin);
	text-align: center;

	${media.lessThan('large')`
    padding: 0 var(--mobileGlobalPadding);
		margin: 30px auto var(--globalTopBottomMargin);
  `}

	strong {
		font-weight: 700;
	}
`

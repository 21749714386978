import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const PricesContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
	max-width: var(--maxWidth);
	margin: 0 auto 60px;

	${media.lessThan('huge')`
    padding: 0 var(--mobileGlobalPadding);
  `}

	${media.lessThan('large')`
    display: flex;
    flex-direction: column;
  `}
`
export const GridArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #eee;
	height: 100%;
	text-align: center;

	img {
		width: 100%;
	}
`

export const Image = styled(Img)`
  width: 100%;
`

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

function Prices () {
	const image = useStaticQuery(
    graphql`
      query {
        photo_1: file(relativePath: { eq: "Ricardo-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        photo_2: file(relativePath: { eq: "Ricardo-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
				}
			}
    `
	)

	return(
		<>
			<S.PricesContainer>
				<S.GridArea>
					<S.Image fluid={image.photo_1.childImageSharp.fluid} />
				</S.GridArea>

				<S.GridArea>
					<S.Image fluid={image.photo_2.childImageSharp.fluid} />
				</S.GridArea>
			</S.PricesContainer>
		</>
	)
}

export default Prices

import styled from 'styled-components'
import media from 'styled-media-query'

export const IntroContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--mobileGlobalPadding);
  margin: var(--globalTopBottomMargin) 0;
  text-align: center;
`

export const Text = styled.p`
  max-width: 600px;
  font-size: 1.3em;
  line-height: 1.4em;

  em {
      font-style: italic;
    }

  ${media.lessThan('medium')`
    font-size: 1.1em;
    line-height: 1.3em;
  `}

  strong {
    font-weight: 700;
    color: var(--primaryColor);
  }
`

import styled from 'styled-components'
import media from 'styled-media-query'


export const FooterContainer = styled.section`
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--white);
  font-size: 0.9em;
`

export const FooterDividerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--maxWidth);
  margin: 0 auto;
  min-height: 50px;
  padding: 0 var(--mobileGlobalPadding);
  color: var(--white);

  ${media.lessThan('large')`
    flex-direction: column;
    padding: 16px var(--mobileGlobalPadding);
  `}
`

export const Copy = styled.div`
  ${media.lessThan('large')`
    order: 2;
  `}
`

export const SocialNetwork = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: 6px;
    color: var(--white);
  }

  ${media.lessThan('large')`
    order: 1;
    margin-bottom: 14px;
  `}
`

export const Author = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(2, 7, 93, 0.9);
  color: var(--white);
  font-size: 0.9em;
  padding: 10px 0;

  svg {
    color: red;
  }

  em {
    font-style: italic;

    a {
      text-decoration: none;
      color: var(--white);
    }
  }

`

import React from 'react'
import { SocialInstagram as Instagram } from 'styled-icons/typicons/SocialInstagram'
import { FacebookSquare as Facebook } from 'styled-icons/boxicons-logos/FacebookSquare'
import { Heart } from 'styled-icons/boxicons-solid/Heart'
import * as S from './styled'

function Footer () {
  return (
    <footer>
      <S.FooterContainer>
        <S.FooterDividerContent>
          <S.Copy>
            <p>&copy; 2020 Vento Sul 3. All right reserved.</p>
          </S.Copy>
          <S.SocialNetwork>
            <p>Connect with us:</p>

            <a href='https://instagram.com/ventosul3' target='_blank' rel='noopener noreferrer'>
              <Instagram size='26' />
            </a>

            <a href='https://facebook.com/ventosul3' target='_blank' rel='noopener noreferrer'>
              <Facebook size='26' />
            </a>
          </S.SocialNetwork>
        </S.FooterDividerContent>
      </S.FooterContainer>

      <S.Author>
        <p>
          Made with <Heart size='16' /> by <em><a href='https://jandir.co' target='_blank' rel='noopener noreferrer'>Jandir Alceu</a></em>
        </p>
      </S.Author>
    </footer>
  )
}

export default Footer

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

function Itinerary() {
	const images = useStaticQuery(
		graphql`
			query {
				imageOne: file(
					relativePath: { eq: "itinerary/praia-do-flamengo.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageThree: file(relativePath: { eq: "itinerary/ilha-fiscal.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageFour: file(relativePath: { eq: "itinerary/museu-do-amanha.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageFive: file(
					relativePath: { eq: "itinerary/museu-contemporaneo.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageSix: file(relativePath: { eq: "itinerary/baia-charitas.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageSeven: file(
					relativePath: { eq: "itinerary/fortaleza-santa-cruz.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageEight: file(relativePath: { eq: "itinerary/forte-da-laje.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}

				imageNine: file(relativePath: { eq: "itinerary/praia-da-urca.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	)

	return (
		<S.ItineraryContainer>
			<S.GridItineraryImages>
				<S.ImageContainer>
					<S.Image fluid={images.imageOne.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Praia do Flamengo</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageThree.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Ilha Fiscal</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageFour.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Museu do Amanhã</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageFive.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>
							Museu de Arte Contemporânea
						</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageSix.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Baia Charitas</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageSeven.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Fortaleza Santa Cruz</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageEight.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Fortaleza da Laje</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>

				<S.ImageContainer>
					<S.Image fluid={images.imageNine.childImageSharp.fluid} />
					<S.ItineraryNameContainer>
						<S.ItineraryNameText>Praia de Urca</S.ItineraryNameText>
					</S.ItineraryNameContainer>
				</S.ImageContainer>
			</S.GridItineraryImages>
		</S.ItineraryContainer>
	)
}

export default Itinerary

import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const  Carrousel = styled.section`
  display: flex;
  height: auto;
  width: 100%;

  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 240px 240px;
  `}
`

export const Image = styled(Img)`
  width: 800px;
  object-fit: contain;

  ${media.lessThan('medium')`
    display: grid;
    width: auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: 240px 240px;
    object-fit: cover;
  `}
`
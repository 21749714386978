import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

function Carrousel () {
  const image = useStaticQuery(
    graphql`
      query {
        photo_1: file(relativePath: { eq: "photo_1.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        photo_2: file(relativePath: { eq: "photo_2.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        photo_3: file(relativePath: { eq: "photo_3.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        photo_4: file(relativePath: { eq: "photo_4.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  
  return (
    <S.Carrousel>
      <S.Image fluid={image.photo_1.childImageSharp.fluid} />
      <S.Image fluid={image.photo_2.childImageSharp.fluid} />
      <S.Image fluid={image.photo_3.childImageSharp.fluid} />
      <S.Image fluid={image.photo_4.childImageSharp.fluid}  />
    </S.Carrousel>
  )
}

export default Carrousel

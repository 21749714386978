import React from 'react'
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp'
import * as S from './styled'

function Prices () {
	return(
		<>
			<S.PricesContainer>
				<S.GridArea>
					<S.Title>Individual</S.Title>
					<S.Time>Passeio cultural de aproximadamente 3h</S.Time>
					<S.IndividualPrice>R$ 215,00</S.IndividualPrice>
					<S.Appointment href="#">AGENDAR</S.Appointment>
				</S.GridArea>

				<S.GridArea>
					<S.Title>Grupos</S.Title>
					<S.Time>Passeio de aproximadamente 3h</S.Time>
					<S.GroupPrices>
						<S.Price><span>08 Pessoas</span><strong>R$ 1.575,00</strong></S.Price>
						<S.Price><span>09 Pessoas</span><strong>R$ 1.700,00</strong></S.Price>
						<S.Price><span>10 Pessoas</span><strong>R$ 1.750,00</strong></S.Price>
					</S.GroupPrices>

					<S.Contact>
						<p>Reserve ja</p>
						<a href="tel:+5521999859178"><Whatsapp size="40" /><span>(21) 99985-9178</span></a>
					</S.Contact>
				</S.GridArea>
			</S.PricesContainer>

			<S.Notes style={{ textAlign: 'center' }}>
				<strong>Obs:</strong>Embarque e desembarque na Urca - Em frente ao Bar Urca.
			</S.Notes>
		</>
	)
}

export default Prices

import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const ItineraryContainer = styled.section`
	display: flex;
	flex-direction: column;
	max-width: var(--maxWidth);
	margin: 0 auto var(--globalTopBottomMargin);

	${media.lessThan('huge')`
    padding: 0 var(--mobileGlobalPadding);
  `}
`

export const GridItineraryImages = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 250px);
	grid-gap: 10px;

	${media.lessThan('large')`
    display: flex;
    flex-direction: column;
  `}
`

export const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	&:nth-child(odd) {
		background-color: #f8f8f8;
	}

	&:nth-child(even) {
		background-color: #f0f0f0;
	}

    ${media.lessThan('large')`
      margin-bottom: 8px;
      flex-direction: row;
    `}
`

export const Image = styled(Img)`
	width: 100%;
	height: 100%;
	object-fit: contain;
	position: absolute;

    ${media.lessThan('large')`
        width: 120px;
        height: 60px;
        position: static;
    `}
`

export const ItineraryNameContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 42px;
	background-color: rgba(0, 0, 0, 0.8);
	color: var(--white);

    ${media.lessThan('large')`
        justify-content: flex-start;
        background-color: transparent;
        height: auto;
        color: var(--black);
        position: static;
        padding: 0 10px 0 20px;
    `}
`
export const ItineraryNameText = styled.p``

import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Carrousel from '../components/Carrousel'
import Intro from '../components/Intro'
import Itinerary from '../components/Itinerary'
import Prices from '../components/Prices'
import Footer from '../components/Footer'
import EtineraryAndItems from '../components/EtineraryAndItems'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Carrousel />
    <Intro />
		<EtineraryAndItems />
    <Itinerary />
		<Prices />
    <Footer />
  </Layout>
)

export default IndexPage
